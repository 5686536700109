* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  --main-header-from: #3a7bd5;
  --main-header-to: #3a6073;
  --search-size: 50vw;
  --input: #828282;
  --card-bg: #efefef;
  --list-focus: #d9d9d9;
  --text-color: #303d4f;
  --skeleton-color: #d4d4d4;
}

/* top container */

.top-container {
  background-image: linear-gradient(
    var(--main-header-from),
    var(--main-header-to)
  );
}
.nav img {
  margin-right: 10px;
}
.nav {
  display: flex;
  align-items: center;
  padding: 30px;
  font-weight: 600;
  font-size: 30px;
  color: white;
}
.nav .menu {
  width: 45px;
  margin-left: auto;
  cursor: pointer;
  z-index: 2;
  margin-top: 38px;
  margin-right: 20px;
  padding: 6px;
  border-radius: 4px;
  position: fixed;
  top: 0;
  right: 0;
  background-image: linear-gradient(#3a79cb, #3a76c3);
}
.top-container h2 {
  color: white;
  font-weight: 500;
  font-size: 26px;
  max-width: 65vw;
  text-align: center;
  margin: 45px auto 30px auto;
}
.wrapper {
  max-width: var(--search-size);
  margin: auto;
}
.wrapper {
  padding-top: 10px;
  padding-bottom: 80px;
  width: 100%;
  border-radius: 5px;
}
.search-input {
  position: relative;
}
.search-input input {
  color: var(--input);
  padding: 14px 14px 14px 50px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}
.search-input .icon {
  position: absolute;
  left: 10px;
  top: 8px;
  width: 35px;
}
.search-input .close {
  position: absolute;
  right: 15px;
  top: 16px;
  width: 16px;
  cursor: pointer;
}
.autocom-box {
  margin-top: 3px;
  position: absolute;
  z-index: 1;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  /* over flow condition */
  max-height: 160px;
  overflow-y: auto;
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.2);
}
.autocom-box::-webkit-scrollbar-track {
  border-radius: 250px;
}
.autocom-box::-webkit-scrollbar {
  width: 6px;
}
.autocom-box::-webkit-scrollbar-thumb {
  background: #bcc0c4;
  margin-right: 10px;
  border-radius: 250px;
}
.autocom-box li {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 12px;
  width: 100%;
  border-radius: 5px;
}
.autocom-box li img {
  width: 30px;
  margin-right: 10px;
}
.autocom-box li:hover {
  background-color: var(--card-bg);
}
.autocom-box .active {
  background-color: var(--card-bg);
}
.autocom-box li:focus {
  background-color: var(--list-focus);
  outline: none;
}

/* resources container*/

.resources-container {
  padding: 60px;
}
.category {
  margin-bottom: 25px;
}
.category h2 {
  font-weight: 500;
  font-size: 26px;
  color: var(--main-header-from);
}
.card-container {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px 30px;
}
.card {
  text-align: center;
  color: var(--text-color);
  padding: 25px 28px;
  border-radius: 10px;
  background-color: var(--card-bg);
  cursor: pointer;
}
/* .card:hover {
  transform: scale(1.01, 1.01);
} */
.card img {
  width: 45px;
  border-radius: 10px;
}
.card h3 {
  margin-top: 10px;
  font-size: 20px;
}
.card p {
  text-align: left;
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.4;
}
button {
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid var(--main-header-from);
  border-radius: 6px;
  color: var(--main-header-from);
  background-color: white;
  display: flex;
  justify-content: center;
  float: right;
}
button:hover {
  background-color: #3a7bd540;
  cursor: pointer;
}
button img {
  width: 20px;
  margin-right: 5px;
}

/* footer */

footer {
  text-align: center;
  padding: 30px;
  background-color: var(--text-color);
  color: white;
  font-size: 22px;
  font-weight: 400;
}
footer a {
  color: white;
  text-decoration: none;
}
footer a:hover {
  text-decoration: underline;
}

/* drawer */

.drawer-container {
  background-color: white;
  width: 30%;
  height: 100vh;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  /* animation-duration: 0.4s;
  animation-name: slidein;
  animation-direction: alternate; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 20px 30px;
  border-bottom: 1px solid #e4e4e4;
}
.drawer-header h2 {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-color);
}
.drawer-header img {
  width: 28px;
  cursor: pointer;
}
.drawer-body {
  padding: 15px 30px 20px 30px;
  font-size: 18px;
  font-weight: 400;
  color: var(--text-color);
  overflow-y: auto;
}
.drawer-body p {
  margin-bottom: 5px;
  padding: 8px;
  cursor: pointer;
}
.drawer-body p:hover {
  background-color: rgba(240, 240, 240, 0.6);
  border-radius: 5px;
}

@keyframes slidein {
  from {
    width: 0;
  }
  to {
    width: 30%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
}

/* skeleon loading */
.skeleton-wrapper {
  height: 200;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.skeleton-image {
  height: 45px;
  width: 45px;
  background: var(--skeleton-color);
  border-radius: 10px;
}

.skeleton-title {
  height: 20px;
  width: 80px;
  background: var(--skeleton-color);
  border-radius: 5px;
  margin-top: 10px;
}

.skeleton-line {
  height: 20px;
  width: 100%;
  background: var(--skeleton-color);
  border-radius: 5px;
  margin-top: 10px;
}

/* media queries */

@media (max-width: 1200px) {
  .top-container h2 {
    max-width: 85vw;
  }
  * {
    --search-size: 80vw;
  }
}

@media (max-width: 768px) {
  * {
    --search-size: 70vw;
  }
  .top-container h2 {
    max-width: 75vw;
  }
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .resources-container {
    padding: 50px;
  }
  .drawer-container {
    width: 50%;
  }
  @keyframes slidein {
    from {
      width: 0;
    }
    to {
      width: 50%;
    }
  }
}

@media (max-width: 576px) {
  .card-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .resources-container {
    padding: 40px;
  }
  .drawer-container {
    width: 100%;
  }
  @keyframes slidein {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  footer {
    margin-top: 20px;
  }
}
